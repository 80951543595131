input[type=file] {
    max-width: 90%;
    color: #444;
    padding: 1rem;
    background: #fff;
    border-radius: 0.5rem;
    font-family: 'Sora', sans-serif;
  }
input[type=file]::file-selector-button {
    border: none;
    background: #284614;
    padding: 0.75rem;
    border-radius: 0.5rem;
    color: #fff;
    cursor: pointer;
    transition: background .2s ease-in-out;
    font-family: 'Sora', sans-serif;
    font-size: 0.7rem;
  } 
  
input[type=file]::file-selector-button:hover {
    background: #53AD52;
  }